const axios = require('axios');

let urlApi = 'https://mcm-api-web.herokuapp.com/api/v1/controlcreackrisorsaadmin'

export const manda = (dati,callback) =>{
    axios.post(urlApi, dati)
      .then(function (response) {
        console.log(response);
        callback(response)
      })
      .catch(function (error) {
        console.log(error);
      });
}