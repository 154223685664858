import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import './App.css';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { date } from 'yup/lib/locale';
import { parse, isDate } from "date-fns";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import FormikPlacesAutocomplete from "./autocomplete";
import FormikPlacesAutocompleteCity from "./autocompletecity";
import { manda } from "./utils/functionServer";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


var CodiceFiscale = require('codice-fiscale-js');


const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

function _calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  console.log(Math.abs(ageDate.getUTCFullYear() - 1970));
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());

  return parsedDate;
}

const today = new Date( new Date().getFullYear()-18 , new Date().getMonth()-1, new  Date().getDate() );

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  itC: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),

  luogoNas: Yup.object().required('parametro obbligatorio'),
  indRes: Yup.object().required('parametro obbligatorio'),
  cf:Yup.string()
    .required('parametro obbligatorio')
    .matches(/^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i, 'Non è il formato giusto'),
  piva:Yup.string()
    .required('parametro obbligatorio')
    .matches( /^[0-9]{11}$/, 'Non è il formato giusto'),
  iban:Yup.string()
    .required('parametro obbligatorio')
    .matches( /^(?:(?:IT|SM)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i, 'Non è il formato giusto'),  
  secondoNome: Yup.string()
    .min(2, 'troppo corto!')
    .max(50, 'troppo lungo!'),
  cell: Yup.number()
    .min(10, 'Too Short!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  dataNascita: Yup.date().required('Required').transform(parseDateString).max(today,'Devi avere almeno 18 anni'),  
  email: Yup.string().email('Invalid email').required('Required'),
  pec: Yup.string().email('Invalid email'),
});

const App = () => {

  const [loading,setLoading] = useState(false)
  const [mess,setMess] = useState(null)

  const ckCodiceF  = (dati) =>{
    console.log(dati);
    var cf = new CodiceFiscale(dati.cf);
    console.log('mostro dati');
    console.log(cf);

    let dateObj = new Date(dati.dataNascita)

    console.log('_date_');
    console.log(cf.birthday);
    console.log(dateObj);

    console.log('luogo');
    console.log(cf.birthplace.nome);
    console.log(dati.luogoNas);
    
    if(!datesAreOnSameDay(cf.birthday, dateObj)){
      alert('controlla la data di nascita? (non coincide con i dati del codice fiscale)')
    }else{
      if(!dati.luogoNas.value.toUpperCase().includes(cf.birthplace.nome.toUpperCase())){
        alert('controlla il comune di nascita? (non coincide con i dati del codice fiscale)')
      }else{
        console.log('qui arrivo');
        setLoading(true)
        manda(dati,(risultato)=>{
          console.log('ecco');
          console.log(risultato);
          setLoading(false)
          setMess(risultato.data.mess)
        })
      }
    }
    

    
    
      //alert('ok tutto ok vediamo altri controlli server')

    
  }

  return (
  <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
    <div className="relative py-3 sm:max-w-xl sm:mx-auto">
    <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
      <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
        <div className="max-w-md mx-auto">
          <div>
            <img src="https://www.mcmcomunicazione.com/wp-content/themes/mcm/img/MCM_Logo.svg" className="h-7 sm:h-8" />
          </div>
          <div className="text-center">
                <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Aggiungi le tue informazioni</h1>
                <p className="text-gray-400 dark:text-gray-400">Fill up the form below to send us a message.</p>
            </div>

          <div className="divide-y divide-gray-200">
            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
            {loading ? (
               <div className="text-center">
                <Loader
                  type="Puff"
                  color="#00BFFF"
                  height={100}
                  width={100} //3 secs
                />
                </div>
            ) : (
              mess ? (
                <div className="text-center">
                  <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">{mess.titolo}</h1>
                  <p className="text-gray-400 dark:text-gray-400">{mess.desc}</p>
                </div>
              ) : (
                <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                dataNascita:null,
                cell:''
              }}
              validationSchema={SignupSchema}
              onSubmit={values => {
                // same shape as initial values
                ckCodiceF(values)
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Nome</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"  name="firstName" />
                      {errors.firstName && touched.firstName ? (
                        <div style={{marginBottom:10,color:'red'}} className="text-sm font-bold">{errors.firstName}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Secondo nome</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="secondoNome" />
                      {errors.secondoNome && touched.secondoNome ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.secondoNome}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Cognome</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="lastName" />
                      {errors.lastName && touched.lastName ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.lastName}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Email</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="email" type='email' />
                      {errors.email && touched.email  ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.email}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Numero cellulare</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="cell" type='number' />
                      {errors.cell    ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.cell}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Data nascita</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="dataNascita" type='date' />
                      {errors.dataNascita && touched.dataNascita   ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.dataNascita}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Comune di nascita</span>
                      
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="luogoNas" component={FormikPlacesAutocompleteCity} />
                      {errors.luogoNas  ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.luogoNas}</div>
                      ) : null}
                      {/* <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="email" type='email' />
                     {errors.email && touched.email  ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.email}</div>
                      ) : null}*/}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Indirizzo residenza</span>
                    
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="indRes" component={FormikPlacesAutocomplete} />
                      {errors.indRes  ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.indRes}</div>
                      ) : null}
                    {/*<GooglePlacesAutocomplete
                          apiKey="AIzaSyDSnyIFG854kXKB_U0TG8sfWINHIym_ET0"
                      />*/}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Codice fiscale</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="cf"  />
                      {errors.cf  ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.cf}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Partita iva</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="piva"  />
                      {errors.piva   ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.piva}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Iban</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="iban"  />
                      {errors.iban   ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.iban}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Intestatario conto</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="itC"  />
                      {errors.itC  ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.itC}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Pec</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="pec" type='email' />
                      {errors.pec   ? (
                        <div style={{marginBottom:10,color:'red'}}>{errors.pec}</div>
                      ) : null}
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Sito internet</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="sitoWeb"  />
                  </label>
                  <label style={{marginBottom:10}} className="block">
                    <span className="text-gray-700 font-bold">Profilo Instagram</span>
                      <Field className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" name="instagram"  />
                      
                  </label>

                  <label style={{marginBottom:10}} className="block">
                        Selezionando Invia & Accetta, confermi di aver letto l'Informativa sulla Privacy e di accettare i Termini di Servizio.
                  </label>
                  
                  
                
                  <button className="md:w-62 bg-indigo-600 hover:bg-blue-dark text-white font-bold py-3 px-6 rounded-lg mt-3 hover:bg-indigo-500 transition ease-in-out duration-300" type="submit">Invia & Accetta</button>
                </Form>
              )}
            </Formik>
              )

              
         
            )}
           {/* <Formik
                initialValues={{ email: '', password: '' }}
                validate={values => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Required';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                  ) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  
                }) => (
                  <form  onSubmit={handleSubmit}>
                    <label className="block">
                      <span className="text-gray-700">Name</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Secondo nome</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Cognome</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Data nascita</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Indirizzo completo</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Luogo nascita</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Indirizzo completo luogo residenza</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Codice fiscale</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Partita Iva</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Iban</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Intestatario conto</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Mail</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Pec</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Numero cellulare</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Sito internet</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Profilo Instagram</span>
                      <input
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && errors.email}
                    </label>
                    
                    <button type="submit" className="md:w-32 bg-indigo-600 hover:bg-blue-dark text-white font-bold py-3 px-6 rounded-lg mt-3 hover:bg-indigo-500 transition ease-in-out duration-300" disabled={isSubmitting}>
                      Submit
                </button>
                  </form>
                )}
              </Formik>
              
              */}
             
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*<Formik
      initialValues={{ email: '', password: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        
      }) => (
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {errors.email && touched.email && errors.email}
          <input
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          {errors.password && touched.password && errors.password}
          <button type="submit" disabled={isSubmitting}>
            Submit
          </button>
        </form>
      )}
    </Formik>*/}
  </div>)
};

export default App;
